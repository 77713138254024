let GOOGLE_CAPTCHA_SITE_KEY = '';

if (process.env.SERVER === 'development') {
  GOOGLE_CAPTCHA_SITE_KEY = '6LeUTa4UAAAAAMfhjcIaAOphTRODkY7Vzcq_RoeD';
} else if (process.env.SERVER === 'stage') {
  GOOGLE_CAPTCHA_SITE_KEY = '6LeUTa4UAAAAAMfhjcIaAOphTRODkY7Vzcq_RoeD';
} else if (process.env.SERVER === 'production') {
  GOOGLE_CAPTCHA_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
} else {
  GOOGLE_CAPTCHA_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
}

export default {
  googleCaptchaSiteKey: GOOGLE_CAPTCHA_SITE_KEY,
  cookie: {
    bearerName: 'tcb_bearer',
    userType: 'tcb_user_type', // logged in user type or user logged in as
    registerAs: 'tcb_register_as', // tcb_register_as 'cleaner' | 'customer | 'both'
    profileImage: 'tcb_profile_image',
    firstName: 'tcb_first_name',
    lastName: 'tcb_last_name',

  },
  userTypes: { customer: 'customer', cleaner: 'cleaner' },
  gigStatus: {
    gig_created: 1, payment_done: 2, gig_placed: 3,
    worker_assigned: 4,
    worker_reached: 5,
    work_in_progress: 6,
    work_completed: 7,
    gig_cancelled: 8,
    save_as_draft: 9,
    pre_worker_assigned: 10,
    disputed: 11
  },
  gigCleanerStatus: { active: 1, inactive: 2 },
  invitationStatus: { pending: 0, accepted: 1, rejected: 2, expired: 3 },
  invoiceStatus: { draft: 1, pending: 2, paid: 3, refund: 4, cancelled: 5 },
  subscriptionPlanKeys: {oneTime: 'ot', weekly: 'we', biweekly: 'bw', monthly: 'mo'},
  propertyTypes:
  [
    {
      property_type: 'Home',
      sequence: 1,
      key: 'home',
      room_types: [
        { key: 'living_room', sequence: 1, name: 'Living Room' ,value: 0, abbr: 'LR'},
        { key: 'bed_room', sequence: 2, name: 'Bed Room' ,value: 0, abbr: 'BR'},
        { key: 'bath_room', sequence: 14, name: 'Bathroom' ,value: 0, abbr: 'BAT'},
        { key: 'kitchen', sequence: 2, name: 'Kitchen' ,value: 0, abbr: 'K'},
        { key: 'dinning_room', sequence: 4, name: 'Dining Room' ,value: 0, abbr: 'DR'},
        { key: 'home_office', sequence: 5, name: 'Home Office' ,value: 0, abbr: 'HO'},
        { key: 'basement', sequence: 6, name: 'Basement' ,value: 0, abbr: 'BM'},
        { key: 'garage', sequence: 7, name: 'Garage' ,value: 0, abbr: 'G'},
        { key: 'outdoor_areas', sequence: 8, name: 'Outdoor Areas' ,value: 0, abbr: 'OA'},
        { key: 'laundry_room', sequence: 9, name: 'Laundry Room' ,value: 0, abbr: 'LDR'},
        { key: 'attic', sequence: 10, name: 'Attic' ,value: 0, abbr: 'AT'},
        { key: 'playroom', sequence: 11, name: 'Playroom' ,value: 0, abbr: 'PR'},
        { key: 'gym', sequence: 12, name: 'Gym' ,value: 0, abbr: 'G'},
        { key: 'nursery', sequence: 13, name: 'Nursery' ,value: 0, abbr: 'NR'},
        { key: 'family_room', sequence: 15, name: 'Family Room' ,value: 0, abbr: 'FR'},
        { key: 'stairway', sequence: 16, name: 'Stairway' ,value: 0, abbr: 'SW'},
        { key: 'dev', sequence: 17, name: 'Den' ,value: 0, abbr: 'DN'},
        { key: 'hallway', sequence: 18, name: 'Hallway' ,value: 0, abbr: 'HW'},
        { key: 'pantry', sequence: 19, name: 'Pantry' ,value: 0, abbr: 'PN'},
        { key: 'sunroom', sequence: 20, name: 'Sunroom' ,value: 0, abbr: 'SR'},
        { key: 'other_indoor', sequence: 21, name: 'Other - Indoor' ,value: 0, abbr: 'OI'},
        { key: 'other_outdoor', sequence: 22, name: 'Other - Outdoor' ,value: 0, abbr: 'OO'}
      ]
    },
    {
      property_type: 'Business/Commercial',
      sequence: 2,
      key: 'business_commercial',
      room_types: [
        { key: 'bath_room', sequence: 14, name: 'Bathroom' ,value: 0, abbr: 'BAT'},
        { key: 'Lobby', sequence: 1, name: 'Lobby' ,value: 0, abbr: 'L'},
        { key: 'office', sequence: 2, name: 'Office' ,value: 0, abbr: 'O'},
        { key: 'Conference_room', sequence: 2, name: 'Conference Room' ,value: 0, abbr: 'CR'},
        { key: 'break_room', sequence: 4, name: 'Break Room' ,value: 0, abbr: 'BKR'},
        { key: 'restroom', sequence: 5, name: 'Restroom' ,value: 0, abbr: 'RR'},
        { key: 'waiting_area', sequence: 6, name: 'Waiting Area' ,value: 0, abbr: 'WA'},
        { key: 'storage_room', sequence: 7, name: 'Storage Room' ,value: 0, abbr: 'SR'},
        { key: 'retail_area', sequence: 8, name: 'Retail Area' ,value: 0, abbr: 'RA'},
        { key: 'kitchen_cafeteria', sequence: 9, name: 'Kitchen/Cafeteria' ,value: 0, abbr: 'KC'},
        { key: 'workshop', sequence: 10, name: 'Workshop' ,value: 0, abbr: 'WS'},
        { key: 'warehouse', sequence: 11, name: 'Warehouse' ,value: 0, abbr: 'WH'},
        { key: 'parking_lot', sequence: 12, name: 'Parking Lot' ,value: 0, abbr: 'PL'},
        { key: 'elevator', sequence: 13, name: 'Elevator' ,value: 0, abbr: 'EL'},
        { key: 'other_indoor', sequence: 14, name: 'Other - Indoor' ,value: 0, abbr: 'OI'},
        { key: 'other_outdoor', sequence: 15, name: 'Other - Outdoor' ,value: 0, abbr: 'OO'}
      ]
    }
  ]
};
