import config from '@/config'

export const state = {
  userProfilePicture:
    localStorage.getItem(config.cookie.userProfilePicture) !== undefined &&
      localStorage.getItem(config.cookie.userProfilePicture) !== ''
      ? localStorage.getItem(config.cookie.userProfilePicture)
      : '',
  postProject: {
    projectName: '',
    projectDescription: '',
    projectDocuments: [],
    tmpProjectDocuments: []
  },
  displayUserProfileAccount: false,
  displayUserProfilePicture: false,
  displayUserProfileOtherDetails: false,
  displayUserProfileLocation: false,
  displayUserProfilePhone: false,
  displayUserProfileCategory: false,
  displayUserProfileSkill: false
}

export const mutations = {
  setUserProfilePictureMutation (state) {
    state.userProfilePicture = localStorage.getItem(
      config.cookie.userProfilePicture
    )
  },
  setPostProjectMutation (state, postProject) {
    state.postProject.projectName = postProject.projectName
    state.postProject.projectDescription = postProject.projectDescription
    state.postProject.projectDocuments = postProject.projectDocuments
    state.postProject.tmpProjectDocuments = postProject.tmpProjectDocuments
  },
  setDisplayUserProfileAccountMutation (state, flag) {
    state.displayUserProfileAccount = flag
  },
  setDisplayUserProfilePictureMutation (state, flag) {
    state.displayUserProfilePicture = flag
  },
  setDisplayUserProfileOtherDetailsMutation (state, flag) {
    state.displayUserProfileOtherDetails = flag
  },
  setDisplayUserProfileLocationMutation (state, flag) {
    state.displayUserProfileLocation = flag
  },
  setDisplayUserProfilePhoneMutation (state, flag) {
    state.displayUserProfilePhone = flag
  },
  setDisplayUserProfileCategoryMutation (state, flag) {
    state.displayUserProfileCategory = flag
  },
  setDisplayUserProfileSkillMutation (state, flag) {
    state.displayUserProfileSkill = flag
  }
}

export const actions = {
  setUserProfilePictureAction (context, profilePicture) {
    localStorage.setItem(config.cookie.userProfilePicture, profilePicture)
    context.commit('setUserProfilePictureMutation')
  },
  setPostProjectAction (context, postProject) {
    context.commit('setPostProjectMutation', postProject)
  },
  setDisplayUserProfileAccountAction (context, flag) {
    context.commit('setDisplayUserProfileAccountMutation', flag)
  },
  setDisplayUserProfilePictureAction (context, flag) {
    context.commit('setDisplayUserProfilePictureMutation', flag)
  },
  setDisplayUserProfileOtherDetailsAction (context, flag) {
    context.commit('setDisplayUserProfileOtherDetailsMutation', flag)
  },
  setDisplayUserProfileLocationAction (context, flag) {
    context.commit('setDisplayUserProfileLocationMutation', flag)
  },
  setDisplayUserProfilePhoneAction (context, flag) {
    context.commit('setDisplayUserProfilePhoneMutation', flag)
  },
  setDisplayUserProfileCategoryAction (context, flag) {
    context.commit('setDisplayUserProfileCategoryMutation', flag)
  },
  setDisplayUserProfileSkillAction (context, flag) {
    context.commit('setDisplayUserProfileSkillMutation', flag)
  }
}

export const getters = {
  getUserProfilePicture (state) {
    return state.userProfilePicture
  },
  getPostProject (state) {
    return state.postProject
  },
  getDisplayUserProfileAccount (state) {
    return state.displayUserProfileAccount
  },
  getDisplayUserProfilePicture (state) {
    return state.displayUserProfilePicture
  },
  getDisplayUserProfileOtherDetails (state) {
    return state.displayUserProfileOtherDetails
  },
  getDisplayUserProfileLocation (state) {
    return state.displayUserProfileLocation
  },
  getDisplayUserProfilePhone (state) {
    return state.displayUserProfilePhone
  },
  getDisplayUserProfileCategory (state) {
    return state.displayUserProfileCategory
  },
  getDisplayUserProfileSkill (state) {
    return state.displayUserProfileSkill
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
