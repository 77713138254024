// console.log("My data")
// console.log(process.env)

let VUE_APP_URL = ''
let VUE_DEFAULT_API_URL = ''
let VUE_APP_API_URL = ''
let VUE_APP_SOCKET_API_URL = ''
let VUE_BLOG_URL = ''
let GOOGLE_CAPTCHA_SITE_KEY = ''
let CONNECT_PAYPAL_CLIENT_ID = ''
let CONNECT_PAYPAL_URL = ''
let CONNECT_PAYPAL_REDIRECT_URI = ''

let SOCIAL_MEDIA_FACEBOOK_URL = 'https://www.facebook.com/thecobuilders/'
let SOCIAL_MEDIA_TWITTER_URL = 'https://twitter.com/thecobuilders'
let SOCIAL_MEDIA_YOUTUBE_URL = 'https://www.youtube.com/channel/UCOOb54mvzHpJNM8-wznojcw'
let SOCIAL_MEDIA_LINKEDIN_URL = 'https://www.linkedin.com/company/the-cobuilders/'

if (process.env.SERVER === 'production') {
  let protocol = window.location.protocol
  let hostName = window.location.hostname
  VUE_APP_URL = protocol + '//' + hostName
  VUE_DEFAULT_API_URL = process.env.VUE_APP_BASE_API_URL
  VUE_APP_API_URL = VUE_DEFAULT_API_URL + '/api'
  VUE_BLOG_URL = 'https://blog.cobuilders.com/'
  VUE_APP_SOCKET_API_URL = 'https://api.thecobuilders.com'
  GOOGLE_CAPTCHA_SITE_KEY = '6LeUTa4UAAAAAMfhjcIaAOphTRODkY7Vzcq_RoeD'
  CONNECT_PAYPAL_REDIRECT_URI = 'https://api.thecobuilders.com/api/profilesettings/paypalauthcode?state='
  CONNECT_PAYPAL_CLIENT_ID = 'AbhAL6ERzSB5sb05k85fhESBzdt1ZoBukjTIUAOHRcJVMLTC6LoWusLpNmp4h--0_YZ6suGNCSPWSl_M'
  // CONNECT_PAYPAL_URL = 'https://www.paypal.com/connect?flowEntry=static&client_id=' + CONNECT_PAYPAL_CLIENT_ID + '&scope=openid%20profile%20email%20address%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' + CONNECT_PAYPAL_REDIRECT_URI
  CONNECT_PAYPAL_URL =
    'https://www.paypal.com/connect?flowEntry=static&client_id=' +
    CONNECT_PAYPAL_CLIENT_ID +
    '&scope=openid%20profile%20email%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' +
    CONNECT_PAYPAL_REDIRECT_URI

  // Adsense Js
  let script = document.createElement('script')
  script.setAttribute('data-ad-client', 'ca-pub-1954198710767343')
  script.setAttribute('async', '')
  script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
  document.getElementsByTagName('head')[0].appendChild(script)
} else if (process.env.SERVER === 'qa') {
  let protocol = window.location.protocol
  let hostName = window.location.hostname
  VUE_APP_URL = protocol + '//' + hostName
  VUE_DEFAULT_API_URL = process.env.VUE_APP_BASE_API_URL
  VUE_APP_API_URL = VUE_DEFAULT_API_URL + '/app'
  VUE_BLOG_URL = 'https://blog.cobuilders.com/'
  VUE_APP_SOCKET_API_URL = 'https://api.thecobuilders.com'
  GOOGLE_CAPTCHA_SITE_KEY = '6LeUTa4UAAAAAMfhjcIaAOphTRODkY7Vzcq_RoeD'
  CONNECT_PAYPAL_REDIRECT_URI = 'https://api.thecobuilders.com/api/profilesettings/paypalauthcode?state='
  CONNECT_PAYPAL_CLIENT_ID = 'AbhAL6ERzSB5sb05k85fhESBzdt1ZoBukjTIUAOHRcJVMLTC6LoWusLpNmp4h--0_YZ6suGNCSPWSl_M'
  CONNECT_PAYPAL_URL =
    'https://www.paypal.com/connect?flowEntry=static&client_id=' +
    CONNECT_PAYPAL_CLIENT_ID +
    '&scope=openid%20profile%20email%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' +
    CONNECT_PAYPAL_REDIRECT_URI
} else if (process.env.SERVER === 'stage') {
  let protocol = window.location.protocol
  let hostName = window.location.hostname
  VUE_APP_URL = protocol + '//' + hostName
  VUE_DEFAULT_API_URL = 'https://v2api-stage.thecobuilders.com'
  VUE_APP_API_URL = VUE_DEFAULT_API_URL + '/app'
  VUE_BLOG_URL = 'https://apistage.thecobuilders.com/blog'
  VUE_APP_SOCKET_API_URL = 'https://apistage.thecobuilders.com'
  GOOGLE_CAPTCHA_SITE_KEY = '6LeUTa4UAAAAAMfhjcIaAOphTRODkY7Vzcq_RoeD'
  CONNECT_PAYPAL_REDIRECT_URI = 'https://apistage.thecobuilders.com/api/profilesettings/paypalauthcode?state='
  CONNECT_PAYPAL_CLIENT_ID = 'AcwqrX8W4zJZ1cjWN5I3t0B9TgXBspdsLtl4Ss_Hpf1c_1bEuuca2mj8UP8t9oTWsKENOFf8boGdviYm'
  CONNECT_PAYPAL_URL =
    'https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=' +
    CONNECT_PAYPAL_CLIENT_ID +
    '&scope=openid%20profile%20email%20address%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' +
    CONNECT_PAYPAL_REDIRECT_URI
  // -- LIVE PAYPAL ---//
  // CONNECT_PAYPAL_CLIENT_ID = 'AbhAL6ERzSB5sb05k85fhESBzdt1ZoBukjTIUAOHRcJVMLTC6LoWusLpNmp4h--0_YZ6suGNCSPWSl_M'
  // CONNECT_PAYPAL_URL = 'https://www.paypal.com/connect?flowEntry=static&client_id=' + CONNECT_PAYPAL_CLIENT_ID + '&scope=openid%20profile%20email%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' + CONNECT_PAYPAL_REDIRECT_URI
} else {
  let protocol = window.location.protocol
  let hostName = window.location.hostname
  let appPort = 8080
  let apiPort = 1337
  VUE_APP_URL = protocol + '//' + hostName + ':' + appPort
  VUE_DEFAULT_API_URL = process.env.VUE_APP_BASE_API_URL
  VUE_APP_API_URL = VUE_DEFAULT_API_URL + '/app'
  VUE_BLOG_URL = VUE_APP_URL + 'blog'
  VUE_APP_SOCKET_API_URL = protocol + '//' + hostName + ':' + apiPort
  GOOGLE_CAPTCHA_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  CONNECT_PAYPAL_REDIRECT_URI = 'http://127.0.0.1:1337/api/profilesettings/paypalauthcode?state='
  CONNECT_PAYPAL_CLIENT_ID = 'AcwqrX8W4zJZ1cjWN5I3t0B9TgXBspdsLtl4Ss_Hpf1c_1bEuuca2mj8UP8t9oTWsKENOFf8boGdviYm'
  CONNECT_PAYPAL_URL =
    'https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=' +
    CONNECT_PAYPAL_CLIENT_ID +
    '&scope=openid%20profile%20email%20address%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' +
    CONNECT_PAYPAL_REDIRECT_URI

  //  CONNECT_PAYPAL_CLIENT_ID = 'AbhAL6ERzSB5sb05k85fhESBzdt1ZoBukjTIUAOHRcJVMLTC6LoWusLpNmp4h--0_YZ6suGNCSPWSl_M'
  //  CONNECT_PAYPAL_URL = 'https://www.paypal.com/connect?flowEntry=static&client_id=' + CONNECT_PAYPAL_CLIENT_ID + '&scope=openid%20profile%20email%20address%20https://uri.paypal.com/services/paypalattributes&redirect_uri=' + CONNECT_PAYPAL_REDIRECT_URI
}
export default {
  versionId: 'V30.3.19-1',
  status: {
    success: 'success',
    error: 'error'
  },
  cookie: {
    bearerName: 'tcb_bearer',
    loggingDetails: 'tcb_logging_details',
    signOutEvent: 'tcb-signout-event',
    signInEvent: 'tcb-signin-event',
    userEmail: 'tcb_email',
    userDetails: 'tcb_user_details',
    userType: 'tcb_user_type',
    userId: 'userId',
    firstName: 'tcb_first_name',
    lastName: 'tcb_last_name',
    userProfilePicture: 'tcb_user_profile_picture',
    chatNotificationDetails: {
      notificationCount: 'tcb_chat_notification_count'
    },
    proposalDetails: 'tcb_proposal_details'
  },
  appUrl: VUE_APP_URL,
  apiDefaultUrl: VUE_DEFAULT_API_URL,
  socket: {
    url: VUE_APP_SOCKET_API_URL
    // url: process.env.wsApiUrl || 'ws://localhost:3000/socket'
    // url: process.env.wsApiUrl || 'ws://54.213.133.49:3000/socket'
  },
  // apiUrl: process.env.apiUrl || 'http://localhost:1337',
  apiUrl: VUE_APP_API_URL,
  paypalUrl: CONNECT_PAYPAL_URL,
  blogUrl: VUE_BLOG_URL,
  landingPageRoute: '/findopportunities',
  projectDetailPage: 'view',
  uploadProfilePictureSize: 1048576, // 1 MB OR 1024 KB
  uploadProfilePictureURL: VUE_DEFAULT_API_URL + '/uploads/images/profile/',
  uploadCovidResultsURL: VUE_DEFAULT_API_URL + '/uploads/images/covid-results/',
  uploadChatImageURL: VUE_DEFAULT_API_URL + '/uploads/images/chatmeet/',
  uploadFileURL: VUE_DEFAULT_API_URL + '/uploads/',
  // googleCaptchaSiteKey: '6Lc-D64UAAAAAEpDO_GVaS0Au_1KuA-dUpoL_Vjh', // TheCoBuilders V2 => vish.khush.2k17@gmail.com
  // googleCaptchaSiteKey: '6LeUTa4UAAAAAMfhjcIaAOphTRODkY7Vzcq_RoeD', // TheCoBuilders V2 => micheal nelson(TheCoBuilders)
  // googleCaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // Test Key
  googleCaptchaSiteKey: GOOGLE_CAPTCHA_SITE_KEY,
  facebookUrl: SOCIAL_MEDIA_FACEBOOK_URL,
  twitterUrl: SOCIAL_MEDIA_TWITTER_URL,
  youtubeUrl: SOCIAL_MEDIA_YOUTUBE_URL,
  linkedInUrl: SOCIAL_MEDIA_LINKEDIN_URL,
  serviceCharge: 0.15,
  regexNumberDecimalOnly: /^[0-9]\d*(\.\d+)?$/,
  deactivatedUserActionMsg: 'Restricted',
  pagination: {
    project: {
      page: 1,
      limit: 5,
      totalRecord: 0,
      search: '',
      filterSubcategoryIds: [],
      filterSkillIds: [],
      filterLocations: [],
      filterPrice: [],
      filterProjectTypes: [],
      status: 'created',
      field: 'in_project_id',
      sorttype: 'desc'
    },
    user: {
      page: 1,
      limit: 10,
      totalRecord: 0,
      search: '',
      status: 'active',
      field: 'um.in_user_id',
      sorttype: 'desc'
    },
    projectLiked: {
      page: 1,
      limit: 5,
      totalRecord: 0,
      search: '',
      status: 'created',
      field: 'in_project_id',
      sorttype: 'desc'
    },
    projectList: {
      page: 1,
      limit: 5,
      totalRecord: 0,
      search: '',
      status: '',
      field: 'in_project_id',
      sorttype: 'desc'
    },
    askQuestion: {
      page: 1,
      limit: 10
    },
    postedProject: {
      page: 1,
      limit: 5,
      totalRecord: 0,
      search: '',
      status: '',
      field: 'in_project_id',
      sorttype: 'desc'
    },
    receivedProposalProject: {
      projectId: '',
      page: 1,
      limit: 10,
      totalRecord: 0,
      search: '',
      status: '',
      field: 'in_project_id',
      sorttype: 'desc'
    },
    donationDetailsProject: {
      projectId: '',
      page: 1,
      limit: 10,
      totalRecord: 0,
      search: '',
      status: '',
      field: 'in_project_id',
      sorttype: 'desc'
    },
    notification: {
      notificationId: '',
      page: 1,
      limit: 10,
      totalRecord: 0,
      search: '',
      status: '',
      field: 'in_notification_id',
      sorttype: 'desc'
    },
    blogListingCategoryWise: {
      page: 1,
      limit: 12,
      totalRecord: 0,
      search: '',
      status: 'active',
      field: 'tbi.in_blog_item_id',
      sorttype: 'desc',
      blogCategoryId: '',
      blogType: 'rss'
    },
    motherdayPosts: {
      page: 1,
      limit: 9
    },
    transactionList: {
      type: '',
      page: 1,
      limit: 10,
      totalRecord: 0,
      search: '',
      status: '',
      // 'field': 'in_project_id',
      sorttype: 'desc'
    }
  },
  defaultProfileComplete: 30,
  totalFieldProfileComplete: 70,
  totalFields: 9,
  // Server statuses
  accountStatus: {
    pending: 'pending',
    active: 'active',
    inactive: 'inactive',
    delete: 'delete'
  },
  commonStatus: {
    active: 'active',
    inactive: 'inactive'
  },
  reasonFor: {
    project: 'project',
    withdrawal: 'withdrawal',
    projectInvitation: 'projectInvitation',
    rejectOffer: 'rejectOffer'
  },
  requestStatus: {
    pending: 'pending',
    accept: 'accept',
    reject: 'reject'
  },
  jobStatus: {
    hired: 'hired',
    closed: 'closed',
    withdrawn: 'withdrawn'
  },
  projectStatus: {
    created: 'created',
    awarded: 'awarded',
    inProgress: 'inProgress',
    review: 'review',
    complete: 'complete',
    autocomplete: 'autocomplete',
    cancel: 'cancel',
    autocancel: 'autocancel',
    cancelled: 'cancelled'
  },
  proposalStatus: {
    active: 'active',
    review: 'review',
    inProgress: 'inProgress',
    accepted: 'accepted',
    closed: 'closed',
    reject: 'reject',
    archive: 'archive',
    cancel: 'cancel',
    rejectProposal: 'rejectProposal'
  },
  proposalSubmitStatus: {
    manual: 'manual',
    invitation: 'invitation'
  },
  projectPayType: {
    project: 'project',
    milestone: 'milestone'
  },
  projectDuration: {
    1: 'Less than 1 week',
    2: 'Less than 1 month',
    3: '1 to 3 months',
    4: '3 to 6 months',
    5: 'More than 6 months'
  },
  cmsPageStatus: {
    editable: 'editable',
    uneditable: 'uneditable'
  },
  paymentStatus: {
    initiated: 'initiated',
    paid: 'paid',
    failed: 'failed',
    requestedRefund: 'requested_refund',
    refunded: 'refunded',
    pending: 'pending'
  },
  blogImgType: {
    image: 'image',
    link: 'link'
  },
  projectMilestoneStatus: {
    created: 'created',
    inProgress: 'inProgress',
    complete: 'complete',
    reject: 'reject',
    delete: 'delete'
  },
  projectUserType: {
    badger: 'badger',
    doer: 'doer',
    giver: 'giver',
    admin: 'admin'
  },
  paymentMethod: {
    Paypal: 'paypal',
    Stripe: 'stripe'
  },
  employeeType: {
    Employee: 'employee',
    'Self Employee': 'selfemployee',
    'Laid Off': 'laidoff'
  },
  UserRole: {
    Trainee: 'trainee',
    Specker: 'speaker'
  },
  walletStatus: {
    initiated: 'initiated',
    pending: 'pending',
    inProgress: 'inProgress',
    success: 'success',
    cancel: 'cancel',
    requested: 'requested',
    paid: 'paid',
    complete: 'complete',
    cancelled: 'cancelled'
  },
  // #TODO: check with the tbl_reasons table.
  reasonSelection: {
    projectDefaultReasonId: 1,
    projectOtherReasonId: 7,
    withdrawDefaultReasonId: 8,
    withdrawOtherReasonId: 13,
    projectInvitationDefaultReasonId: 14,
    projectInvitationOtherReasonId: 19,
    rejectOfferDefaultReasonId: 20,
    rejectOfferOtherReasonId: 25,
    rejectProposalOtherReasonId: 30
  },

  projectTypes: [
    {
      key: 'Fund Raising',
      value: 'fundRaising'
    },
    {
      key: 'Job Posting',
      value: 'jobPosting'
    },
    {
      key: 'Selling (Service, Product or an Idea)',
      value: 'selling'
    },
    {
      key: "I'm looking for Investors",
      value: 'investment'
    }
  ],

  timezones: [
    {
      timezoneCode: 'GMT',
      timezoneName: 'Greenwich Mean Time',
      timezone: 'GMT'
    },
    {
      timezoneCode: 'UTC',
      timezoneName: 'Universal Coordinated Time',
      timezone: 'GMT'
    },
    {
      timezoneCode: 'ECT',
      timezoneName: 'European Central Time',
      timezone: 'GMT+1:00'
    },
    {
      timezoneCode: 'EET',
      timezoneName: 'Eastern European Time',
      timezone: 'GMT+2:00'
    },
    {
      timezoneCode: 'ART',
      timezoneName: '(Arabic) Egypt Standard Time',
      timezone: 'GMT+2:00'
    },
    {
      timezoneCode: 'EAT',
      timezoneName: 'Eastern African Time',
      timezone: 'GMT+3:00'
    },
    {
      timezoneCode: 'MET',
      timezoneName: 'Middle East Time',
      timezone: 'GMT+3:30'
    },
    {
      timezoneCode: 'NET',
      timezoneName: 'Near East Time',
      timezone: 'GMT+4:00'
    },
    {
      timezoneCode: 'PLT',
      timezoneName: 'Pakistan Lahore Time',
      timezone: 'GMT+5:00'
    },
    {
      timezoneCode: 'IST',
      timezoneName: 'India Standard Time',
      timezone: 'GMT+5:30'
    },
    {
      timezoneCode: 'BST',
      timezoneName: 'Bangladesh Standard Time',
      timezone: 'GMT+6:00'
    },
    {
      timezoneCode: 'VST',
      timezoneName: 'Vietnam Standard Time',
      timezone: 'GMT+7:00'
    },
    {
      timezoneCode: 'CTT',
      timezoneName: 'China Taiwan Time',
      timezone: 'GMT+8:00'
    },
    {
      timezoneCode: 'JST',
      timezoneName: 'Japan Standard Time',
      timezone: 'GMT+9:00'
    },
    {
      timezoneCode: 'ACT',
      timezoneName: 'Australia Central Time',
      timezone: 'GMT+9:30'
    },
    {
      timezoneCode: 'AET',
      timezoneName: 'Australia Eastern Time',
      timezone: 'GMT+10:00'
    },
    {
      timezoneCode: 'SST',
      timezoneName: 'Solomon Standard Time',
      timezone: 'GMT+11:00'
    },
    {
      timezoneCode: 'NST',
      timezoneName: 'New Zealand Standard Time',
      timezone: 'GMT+12:00'
    },
    {
      timezoneCode: 'MIT',
      timezoneName: 'Midway Islands Time',
      timezone: 'GMT-11:00'
    },
    {
      timezoneCode: 'HST',
      timezoneName: 'Hawaii Standard Time',
      timezone: 'GMT-10:00'
    },
    {
      timezoneCode: 'AST',
      timezoneName: 'Alaska Standard Time',
      timezone: 'GMT-9:00'
    },
    {
      timezoneCode: 'PST',
      timezoneName: 'Pacific Standard Time',
      timezone: 'GMT-8:00'
    },
    {
      timezoneCode: 'PNT',
      timezoneName: 'Phoenix Standard Time',
      timezone: 'GMT-7:00'
    },
    {
      timezoneCode: 'MST',
      timezoneName: 'Mountain Standard Time',
      timezone: 'GMT-7:00'
    },
    {
      timezoneCode: 'CST',
      timezoneName: 'Central Standard Time',
      timezone: 'GMT-6:00'
    },
    {
      timezoneCode: 'EST',
      timezoneName: 'Eastern Standard Time',
      timezone: 'GMT-5:00'
    },
    {
      timezoneCode: 'IET',
      timezoneName: 'Indiana Eastern Standard Time',
      timezone: 'GMT-5:00'
    },
    {
      timezoneCode: 'PRT',
      timezoneName: 'Puerto Rico and US Virgin Islands Time',
      timezone: 'GMT-4:00'
    },
    {
      timezoneCode: 'CNT',
      timezoneName: 'Canada Newfoundland Time',
      timezone: 'GMT-3:30'
    },
    {
      timezoneCode: 'AGT',
      timezoneName: 'Argentina Standard Time',
      timezone: 'GMT-3:00'
    },
    {
      timezoneCode: 'BET',
      timezoneName: 'Brazil Eastern Time',
      timezone: 'GMT-3:00'
    },
    {
      timezoneCode: 'CAT',
      timezoneName: 'Central African Time',
      timezone: 'GMT-1:00'
    }
  ]
}
