<template>
  <section>
    <!-- Header Start -->
    <div :class="`main_menu_section bg-dark ${$route.meta.nosticky ? 'position-initial': ''}`">
      <div class="container mob_responsive_pad_0">
        <header class="position-relative header-start">
          <nav class="navbar navbar-expand-lg navbar-light" style="display: flex;align-items: center;">
            <router-link class="navbar-brand mob_responsive_pad_l_15" :to="{ path: '/' }">
              <img :src="'/app/logo.svg'" aria-label="TheCoBuilders Logo" alt="TheCoBuilders Logo" />
              <!-- <img :src="'/app/logo-black-bg.png'" aria-label="TheCoBuilders Logo" alt="TheCoBuilders Logo" /> -->
            </router-link>

            <!-- old header -->
            <div class="login-div" v-if="$route.meta.action !== 'book-cleaning' && false">
              <router-link v-if="showSubscription" class="btn primary download-lg" :to="{ path: '/house-cleaning/regular/freshnest-subscriptions/' }" style="margin-right: 20px;color: #f8c401">Subscribe & Save More</router-link>
              <router-link v-if="showDownloadApp" class="btn primary download-lg" :to="{ path: '/house-cleaning/download-app/' }" style="margin-right: 20px">Download App</router-link>
              <router-link v-if="showDownloadApp" class="btn primary download-sm" :to="{ path: '/house-cleaning/download-app/' }">App</router-link>
              <div @click="redirectTo('/house-cleaning-app/login/')" class="btn secondary how-works sign-up-btn">
                Login
              </div>
            </div>
            <!-- old header end -->

            <!-- new header code -->
            <div v-if="$route.meta.action !== 'book-cleaning'" :class="`new-header-div ${headerShow ? 'new-header-show' : 'new-header-hide'}`">
              <button type="button" class="navbar-close-btn" @click="headerShow = false">
                <svg style="height: 22px;width: auto;" fill="white" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                  viewBox="0 0 490 490" xml:space="preserve">
                <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
                  489.292,457.678 277.331,245.004 489.292,32.337 "/>
                </svg>
              </button>
              <div class="header-links">
                <router-link class="nav-link" :to="{ path: '/house-cleaning-tips/blog/' }">Find Cleaning Tips</router-link>
                <router-link class="nav-link" :to="{ path: '/house-cleaning/regular/freshnest-subscriptions/' }">Join FreshNest Club</router-link>
                <router-link class="nav-link" :to="{ path: '/house-cleaning/download-app/' }">Get Cleaner App</router-link>
                <router-link class="nav-btn" :to="{ path: '/cleaning-types/book-house-cleaning/' }">Book Cleaning</router-link>
                <router-link class="nav-link" :to="{ path: '/house-cleaning-app/login/' }">Sign In</router-link>
              </div>
            </div>
            <div v-if="$route.meta.action !== 'book-cleaning'" class="new-header-div-mobile">
              <router-link class="nav-btn" :to="{ path: '/cleaning-types/book-house-cleaning/' }">Book Cleaning</router-link>
              <button type="button" class="navbar-btn" @click="headerShow = true">
                <svg style="height:22px;" width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33.2182 0H1.14546C0.512877 0 0 0.512877 0 1.14545C0 1.77802 0.512877 2.2909 1.14546 2.2909H33.2182C33.8508 2.2909 34.3636 1.77803 34.3636 1.14545C34.3636 0.512866 33.8508 0 33.2182 0Z" fill="white"/>
                  <path d="M33.2182 11.4547H1.14546C0.512877 11.4547 0 11.9676 0 12.6002C0 13.2327 0.512877 13.7456 1.14546 13.7456H33.2182C33.8508 13.7456 34.3636 13.2327 34.3636 12.6002C34.3636 11.9676 33.8508 11.4547 33.2182 11.4547Z" fill="white"/>
                  <path d="M33.2182 22.9091H1.14546C0.512877 22.9091 0 23.4219 0 24.0545C0 24.6871 0.512877 25.2 1.14546 25.2H33.2182C33.8507 25.2 34.3636 24.6871 34.3636 24.0545C34.3636 23.4219 33.8508 22.9091 33.2182 22.9091Z" fill="white"/>
                </svg>
              </button>
            </div>
            <!-- new header code end -->

            <!-- code specific to booking cleaning -->
            <div class="login-div" style="flex-grow: 1;justify-content: right;align-items: center;" v-if="$route.meta.action === 'book-cleaning'">
              <!-- <p class="header-book-cleaning-text"><strong>Book Home Cleaning Service</strong></p> -->
              <h3 class="h3 header-book-cleaning-text">Book Home Cleaning Service</h3>
              <a href="/consumer-privacy-policy" target="_blank" >
                <svg  style="height: 48px;" width="29" height="61" viewBox="0 0 29 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.4654 10C8.01539 10 2.76769 15.2475 2.76769 21.6977V28.3786C2.76769 28.4545 2.67401 28.5825 2.60041 28.6069C1.96783 28.8179 1.45409 29.0007 0.983815 29.182C0.422992 29.3982 0 30.0197 0 30.6277V45.9785C0 46.5824 0.420259 47.2044 0.977528 47.4253C5.28924 49.1339 9.82722 50 14.4654 50C19.1035 50 23.6415 49.1336 27.9534 47.4251C28.5105 47.2043 28.9305 46.5824 28.9305 45.9785V30.6277C28.9305 30.0197 28.5076 29.3982 27.9465 29.1818C27.476 29.0005 26.9623 28.8177 26.3303 28.6067C26.2569 28.5823 26.1632 28.4543 26.1632 28.3783V21.6975C26.1628 15.2475 20.9155 10 14.4654 10ZM11.2793 35.303C11.2793 33.5421 12.7057 32.1171 14.4652 32.1171C16.2246 32.1171 17.6511 33.542 17.6511 35.303C17.6511 36.4699 16.9931 37.4468 16.0581 38.0006V43.2679C16.0581 44.1484 15.3457 44.8608 14.4652 44.8608C13.5846 44.8608 12.8722 44.1484 12.8722 43.2679V38.0006C11.9373 37.4468 11.2793 36.4699 11.2793 35.303ZM22.1409 21.6975V27.4619C19.6238 26.9203 17.0415 26.646 14.4665 26.6458C11.8926 26.6458 9.31081 26.9202 6.78987 27.4613V21.6975C6.78987 17.4652 10.233 14.0222 14.4652 14.0222C18.6974 14.0221 22.1409 17.4652 22.1409 21.6975Z" fill="white"/>
                </svg>
              </a>
            </div>

          </nav>
        </header>
      </div>
    </div>

    <div v-if="!$route.meta.nosticky" class="top-spacing" />
    <!-- Header End -->
  </section>
</template>

<script>
export default {
  name: "V2Header",
  components: {},
  mixins: [],
  props: {},
  data: () => ({
    showDownloadApp: true,
    showSubscription: true,
    headerShow: false
  }),

  async created() {
    if (this.$route.name === "DownloadAppPage") {
      this.showDownloadApp = false;
    } else {
      this.showDownloadApp = true;
    }


    if (this.$route.name === "freshnessSubscription") {
      this.showSubscription = false;
    } else {
      this.showSubscription = true;
    }

    console.log('testing', this.$route.meta.action)

  },

  watch: {
    $route(to, from) {
      // this.socialFooter = to.meta.socialFooter | false;
      // this.appFooter = to.meta.appFooter | false;
      console.log(this.$route.name);
      if (this.$route.name === "DownloadAppPage") {
        this.showDownloadApp = false;
      } else {
        this.showDownloadApp = true;
      }

      if (this.$route.name === "freshnessSubscription") {
      this.showSubscription = false;
    } else {
      this.showSubscription = true;
    }
    },
  },
  methods: {
    redirectTo(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Header.scss";
</style>
