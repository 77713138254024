<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <SiteLoader />
    <router-view />

    <!-- live chat plugin -->
    <!-- <Chat v-if="loadChat"/> -->
  </div>
</template>

<style src="./App.scss" lang="scss"></style>

<script>
import SiteLoader from '@/components/Common/SiteLoader.vue'
import config from '@/config/config'
import Helper from '@/api/helper'
// import Chat from '@/components/Common/Chat.vue'

export default {
  components: {
    SiteLoader,
    // Chat : () => import('@/components/Common/Chat.vue')
  },
  data() {
    return {
      userData: null,
      userProfileImage: null,
      loadChat: false
    }
  },
  mounted: function () {
    // Join socket again to handle the refresh page case.
    if (localStorage.getItem(config.cookie.bearerName)) {
      this.$socketIo.socket.post('/common/socket/join', { token: localStorage.getItem(config.cookie.bearerName) }, (body, jwRes) => {
        console.log(body)
        // GData.roomName = body.roomName
      })

      // Listen
      this.$socketIo.socket.on('test-socket', (data) => {
        console.log(data)
      })
    }
    console.log('app js vue mounted')
  },
  beforeDestroy() {
    this.$socketIo.socket.off('test-socket')
    this.$socketIo.socket.off('chat-connected')
  },
  updated() {
    if (!this.loadChat) {
      this.loadChat = true
    }
  }
}
</script>
