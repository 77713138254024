<template>
  <div class="app-wrapper">
    <v2-header v-if="!isMobile()" />
    <router-view class="main-content" />
    <!-- :key="key" -->
    <v2-footer v-if="!isMobile()" />
  </div>
</template>

<script>
import V2Header from "./components/Header/V2Header";
import V2Footer from "./components/Footer/V2Footer";

export default {
  name: "PublicLayout",
  components: {
    V2Header,
    V2Footer,
  },

  data() {
    return {};
  },

  created() {
    this.isMobileView();
  },
  mounted() {
    // LiveChatWidget.scriptTagVersion();
  },
  methods: {
    isMobileView() {
      if (this.$route.query.device === "mobile") {
        localStorage.setItem("device", 'mobile');
      }
    },

    isMobile() {
      if (localStorage.getItem("device") === 'mobile') {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content {
  flex: 1;
}
</style>
