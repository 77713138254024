
class Helper {
  getFilePathAndName(file) {
    const DIRECTORY_SEPARATOR = '/';

    let ary = file.split(DIRECTORY_SEPARATOR);
    let file_name = ary[ary.length - 1];
    let file_path = '';

    for (let i = 0; i < ary.length - 1; i++) {
      file_path = file_path + ary[i] + DIRECTORY_SEPARATOR;
    }

    return { path: file_path, file_name: file_name };
  }

  getFileUrl(file, w_h) {
    if (file) {
      const f = this.getFilePathAndName(file);

      if (w_h) {
        return process.env.VUE_APP_BASE_API_URL + '/' + f.path + w_h + '_' + f.file_name;
      }
      else {
        return process.env.VUE_APP_BASE_API_URL + '/' + f.path + f.file_name;
      }

    }
    return null;
  }

  getRoomTypeName(propertyTypes, propertyKey, roomKey) {
    // Find the property type with the given key
    const propertyType = propertyTypes.find(type => type.key === propertyKey);

    // If the property type is found, find the room type with the given key
    if (propertyType) {
      const roomType = propertyType.room_types.find(room => room.key === roomKey);

      // If the room type is found, return its name
      if (roomType) {
        return roomType.name;
      } else {
        return ""; // or handle it as needed
      }
    }
  }
}

export { Helper as default };
