import config from '@/config'

export const state = {
  activeRoomName: '',
  totalUnreadMessageCount: 0
}

export const mutations = {
  SET_ROOM (state, roomName) {
    state.activeRoomName = roomName
  },
  SET_TOTAL_UNREAD_MESSAGE_COUNT (state, totalMessageCount) {
    state.totalUnreadMessageCount = totalMessageCount
  }
}

export const actions = {
  setRoom (context, roomName) {
    context.commit('SET_ROOM', roomName)
  },
  setTotalUnreadMessageCount (context, unreadMessageCount) {
    context.commit('SET_TOTAL_UNREAD_MESSAGE_COUNT', unreadMessageCount)
    localStorage.setItem(config.cookie.chatNotificationDetails.notificationCount, unreadMessageCount)
  }
}

export const getters = {
  getRoom (state) {
    return state.activeRoomName
  },
  getTotalUnreadMessageCount (state) {
    return state.totalUnreadMessageCount
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
