import Vue from 'vue';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';
import {Button, Dialog, Select, Option, Message} from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n';
// import VeeValidate from 'vee-validate';
import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js';
import { required, min, max, email } from 'vee-validate/dist/rules.esm.js';
import App from './App.vue';
import router from './router';
import store from './store/index.js';
// import VuePaginate from 'vue-paginate';
import Vuelidate from 'vuelidate';
// import NProgress from 'vue-nprogress';
// import LoadScript from 'vue-plugin-load-script';
// import 'vue-tel-input/dist/vue-tel-input.css';
import messages from './lang';

import '../public/scss/app.scss';
// import './assets/vendor/fontawesome-free/css/all.min.css';
// import { BVModalPlugin, ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import VueSnackbar from 'vue-snack';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
// import { InlineSvgPlugin } from 'vue-inline-svg';
// import VueMq from 'vue-mq';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faEyeSlash, faEye, faChevronLeft, faStar, faStarHalfAlt, faPencilAlt, faTrashAlt, faHouseUser, faSink, faQuoteRight, faInfoCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import VModal from 'vue-js-modal';
// import VueCardFormat from 'vue-credit-card-validation';
// import VuePlaceAutocomplete from 'vue-place-autocomplete';
import * as VueGoogleMaps from 'vue2-google-maps';
// import FlagIcon from 'vue-flag-icon';
import VueMask from 'v-mask';
// import { StripePlugin } from '@vue-stripe/vue-stripe';

// Socket Start
const io = sailsIOClient(socketIOClient);
io.sails.url = process.env.VUE_APP_SOCKET_URL; // 'http://localhost:1338';
io.sails.reconnection = true;
io.sails.useCORSRouteToGetCookie = false;
const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISH_KEY,
  apiVersion: '2023-08-16',
};
// Vue.use(StripePlugin, options);
Vue.prototype.$socketIo = io;
// Socket End


Vue.use(Button)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
// Vue.use(Drawer)
Vue.prototype.$message = Message;

// Vue.use(require('vue-moment'));
// Vue.use(FlagIcon);
// Vue.use(VueCardFormat);
// Vue.use(VuePlaceAutocomplete);
Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyBKeC8Y8mQr_IUUOcM845msWQecneBgpuQ', libraries: 'places' } });

library.add(faPlus, faEyeSlash, faEye, faChevronLeft, faStar, faStarHalfAlt, faPencilAlt, faTrashAlt, faHouseUser, faSink, faQuoteRight, faInfoCircle, faChevronRight);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Vue.use(LoadScript);
// Vue.use(require('vue-script2'));

Vue.use(VueMask);

// Vue.use(Ads.Adsense);
// Vue.use(Ads.InArticleAdsense);
// Vue.use(Ads.InFeedAdsense);
// Vue.use(InlineSvgPlugin);
// Vue.use(VueCarousel);
// Vue.use(NProgress);
// Vue.use(VModal);
// const nprogress = new NProgress();
var SocialSharing = require('vue-social-sharing');

// Vue.use(VueMq, {
//   breakpoints: {
//     // default breakpoints - customize this
//     sm: 562,
//     md: 991,
//     lg: Infinity
//   }
// });

// Vue.component(VueWordCloud.name, VueWordCloud);
Vue.use(Vuelidate);

Vue.use(VueMeta)

// Vue.use(VueMeta, {
//   refreshOnceOnNavigation: true
// });

// Vue.use(VuePaginate);
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
  container: '.chats',
  duration: 500,
  easing: 'easeIn'
});

let snackbarOptions = {
  position: 'bottom-right',
  time: 7000,
  close: true,
  methods: [
    {
      name: 'dummy',
      color: 'green'
    }
  ]
};

Vue.use(SocialSharing);
Validator.extend('required', required);
Validator.extend('min', min);
Validator.extend('max', max);
Validator.extend('email', email);
Vue.use(VeeValidate, {
  useConstraintAttrs: false
});
Vue.use(VueI18n);
Vue.use(VueSnackbar, snackbarOptions);

// Vue.use(ModalPlugin, BVModalPlugin, ToastPlugin);
Vue.use(VueAnalytics, {
  // id: 'UA-158371452-3',
  id: 'UA-158371452-1',
  router
});
Vue.config.productionTip = false;
Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
