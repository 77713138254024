export const state = {
  unreadNotificationCount: 0
}

export const mutations = {
  SET_UNREAD_NOTIFICATION_COUNT (state, unreadNotificationCount) {
    state.unreadNotificationCount = unreadNotificationCount
  }
}

export const actions = {
  setUnreadNotificationCount (context, unreadNotificationCount) {
    context.commit('SET_UNREAD_NOTIFICATION_COUNT', unreadNotificationCount)
  }
}

export const getters = {
  getUnreadNotificationCount (state) {
    return state.unreadNotificationCount
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
