export default {
  msgNoRecordFound: 'No {0} found.',
  msgSomethingWentWrong: 'Something went wrong. Please try again.',
  msgRequiredParamMissing: 'Required parameters are missing.',
  msgFieldIsRequired: '{0} is required.',
  msgFieldShouldHaveMinCharacter: 'Please enter minimum {1} characters and/or digits in {0}.',
  msgFieldShouldHaveMaxCharacter: 'Please enter no more than {1} characters and/or digits in {0}.',
  msgFieldShouldHaveMinCharacterOnly: 'Please enter minimum {1} characters in {0}.',
  msgFieldShouldHaveMaxCharacterOnly: 'Please enter no more than {1} characters in {0}.',
  msgFieldNumeric: '{0} should be numberic.',
  msgFieldDecimal: '{0} should be number and/or decimal with two decimal places.',
  msgFieldAlphanumeric: '{0} should be alphabets and/or numbers only.',
  msgFieldAgreeTermsConditions: 'Please agree on terms & conditions and privacy policy.',
  msgFieldIsGreaterThan: '{0} should greater or equal to {1}.'
}
