<template>
  <div class="loader__introbox" v-if="ShowThis">
    <div class="loader-box">
      <img src="/img/footer-logo.svg" class="tcb-logo" alt="Site Loader" />
      <div class="loader">
        <span class="round r1"></span>
        <span class="round r2"></span>
        <span class="round r3"></span>
        <span class="round r4"></span>
        <span class="round r5"></span>
      </div>
      <div class="messages">
        <p class="message p1">Find Gigs</p>
        <p class="message p2">Post Gigs</p>
        <p class="message p3">Get Info</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  name: 'siteloader',
  components: {},
  data() {
    return {
      ShowThis: true
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.nprogress-busy .loader__introbox {
  visibility: visible;
  opacity: 1;
}

.loader__introbox {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(34, 34, 34, 0.75);
  z-index: 4000;
  font-family: Montserrat;
  visibility: hidden;
  opacity: 0;

  .loader-box {
    text-align: center;
    background: #222;
    padding: 1.5rem;
    border: 3px solid #fbc52c;
    border-radius: 12px;
  }

  .loader {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .round {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 10px;
    height: 8px;
    width: 8px;
    background: #fff;
    animation: fade 1s 1;
    animation-iteration-count: infinite;
  }

  .messages {
    margin-top: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    height: 20px;
  }

  .message {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    text-align: center;
    opacity: 0;
  }

  .r1 {
    animation-delay: 0.2s;
  }
  .r2 {
    animation-delay: 0.4s;
  }
  .r3 {
    animation-delay: 0.6s;
  }
  .r4 {
    animation-delay: 0.8s;
  }
  .r5 {
    animation-delay: 1s;
  }

  .p1 {
    animation: 6s ease infinite forwards w1;
  }
  .p2 {
    animation: 6s ease infinite forwards w2;
  }
  .p3 {
    animation: 6s ease infinite forwards w3;
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes w1 {
    0% {
      opacity: 0;
    }
    16% {
      opacity: 1;
    }
    32% {
      opacity: 0;
    }
  }

  @keyframes w2 {
    32% {
      opacity: 0;
    }
    48% {
      opacity: 1;
    }
    64% {
      opacity: 0;
    }
  }

  @keyframes w3 {
    64% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    96% {
      opacity: 0;
    }
  }

  @media (max-width: 562px) {
    .tcb-logo {
      max-width: 150px;
    }
    .message {
      font-size: 12px;
    }
  }
}
</style>
