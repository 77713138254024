<template>
  <footer>
    <!-- As per on some screens social footer block is not required. -->
    <!-- So we are by default hiding it. -->
    <!-- To display it in router.js add socialFooter: true in meta with route. -->
    <div v-if="socialFooter" class="footer-block">

      <div class="left">
        <!-- <h1>Connect with us</h1> -->
        <h2>Join Our Team</h2>
        <!-- <p>
          You can connect with us via social platforms. click below icons to see
          our official pages.
        </p> -->
        <p>
          Are you passionate about delivering sparkling clean homes to satisfied customers?
          Join our team and embark on a rewarding career as a cleaner with The <a href="https://www.thecobuilders.com/"><span
                        style="color: skyblue;">CoBuilders Cleaning Service</span></a>.
          Grow your business while enjoying the satisfaction of providing top-notch service to our valid clients.
        </p>
        <!-- <ul class="social-links">
          <li>
            <a href="https://www.facebook.com/thecobuilders" target="blank"
              ><img src="/app/facebook.svg" alt=""
            /></a>
          </li>
          <li>
            <a href="https://twitter.com/TheCoBuilders" target="blank"
              ><img src="/app/twitter.svg" alt=""
            /></a>
          </li>
          <li>
            <a href="#"><img src="/app/pinterest.svg" alt="" /></a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCOOb54mvzHpJNM8-wznojcw"
              target="blank`"
              ><img src="/app/youtube.svg" alt=""
            /></a>
          </li>
        </ul> -->
      </div>
      <div class="right">
        <!-- <p>Receive updates on business, tech and more</p> -->
        <p>Get your clean on with The CoBuilders!</p>
        <!-- <div class="subscribe-box">
          <input type="search" placeholder="Enter your email" />
          <button class="btn primary br-4">
            <img src="/app/telegram.svg" alt="" />
          </button>
        </div> -->
        <router-link to="/signup/cleaner">
          <div class="cleaner-signup-btn">
            <button class="btn primary br-4">
              SIGN UP
              <img src="/app/telegram.svg" alt="Telegram Icon | The CoBuilders" />
            </button>
          </div>
        </router-link>
      </div>
    </div>

    <div class="footer-line">
      <div class="container">
        <div class="body">
          <div class="section">
            <div class="heading">Terms and Policy</div>
            <div class="routes">
              <router-link class="route" :to="{ path: '/house-cleaners/contractors/agreement/' }">
                Agreement
              </router-link>
              <router-link
                class="route"
                :to="{ path: '/house-cleaning-services/safety/prohibited-behaviors/' }"
              >
                User Protection
              </router-link>
              <router-link
                class="route"
                :to="{ path: '/consumer-privacy-policy/' }"
              >
                Privacy Policy
              </router-link>
              <router-link
                class="route"
                :to="{ path: '/house-cleaning-jobs/fastart-for-merchants/' }"
              >
                Merchants
              </router-link>
              <router-link
                class="route"
                :to="{ path: '/house-cleaning-services/consumer-terms-and-conditions/' }"
              >Terms </router-link>

              <router-link
                class="route"
                :to="{ path: '/cleaning-types/book-house-cleaning/' }"
              >Book House Cleaning </router-link>
             
              <!-- <router-link
                class="route"
                 :to="{ path: '/cancellation-policy' }">
                Cancellation Policy
              </router-link> -->
            </div>
          </div>
          <div class="section">
            <div class="heading">More</div>
            <div class="routes">
              <router-link class="route" :to="{ path: '/house-cleaning-tips/blog/' }">
                News
              </router-link>
              <router-link class="route" :to="{ path: '/contests' }">
                Contest
              </router-link>
              <router-link class="route" :to="{ path: '/house-cleaning/inquiries/faq/' }">
                FAQs
              </router-link>
              <router-link class="route" :to="{ path: '/house-cleaning/regular/freshnest-subscriptions/' }">
                FreshNest Membership
              </router-link>
              <router-link class="route" :to="{ path: '/cleaning-services/house-cleaning' }">
                Cleaner by Cities
              </router-link>
              <router-link class="route" :to="{ path: '/cleaning-services/house-cleaning/nearme' }">
                Cleaner Nearby
              </router-link>
            </div>
          </div>
          <div class="right-section">
            <div class="social-media-links">
              <a href="https://www.facebook.com/thecobuilders" target="blank" rel="nofollow" 
                ><img src="/app/facebook.svg" alt="Facebook Icon | The Cobuilders" class="fimg2"
              /></a>
              &nbsp;
              <a href="https://twitter.com/TheCoBuilders" target="blank" rel="nofollow" 
                ><img src="/app/twitter.svg" alt="Twitter Icon | The Cobuilders" class="fimg2"
              /></a>
              &nbsp;
              <a
                href="https://www.youtube.com/channel/UCOOb54mvzHpJNM8-wznojcw"
                target="blank`" rel="nofollow" 
                ><img src="/app/youtube.svg" alt="Youtube Icon | The Cobuilders" class="fimg2"
              /></a>
              &nbsp;
              <a
                href="https://www.instagram.com/thecobuildersapp/"
                target="blank`" rel="nofollow" 
                ><img src="/app/instragram.svg" alt="Instagram Icon | The Cobuilders" class="fimg2"
              /></a>
              &nbsp;
              <a
                href="https://www.linkedin.com/company/64289264/admin/feed/posts/"
                target="blank`" rel="nofollow"
                ><img src="/app/linkedin.svg" alt="Linkedin  Icon | The Cobuilders" class="fimg2"
              /></a>
              &nbsp;
              <a
                href="https://in.pinterest.com/thecobuilders/"
                target="blank`" rel="nofollow"
                ><img src="/app/pinterest.svg" alt="Pinterest Icon | The Cobuilders" class="fimg2"
              /></a>
            </div>
            <div class="email">
              <span style="color: silver">
                For technical help send email to
              </span>
              <a
                href="mailto:support@thecobuilders.com"
                style="color: #f8c401"
                target="_blank"
              >
                support@thecobuilders.com
              </a>
            </div>
          </div>
        </div>
        <div class="end-line">
          <div class="copyright">
            <div class="text">
              © 2015-2024, All Rights Reserved to
              <strong>The CoBuilders LLC</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "V2Footer",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      socialFooter: false,
    };
  },
  watch: {
    $route(to, from) {
      this.socialFooter = to.meta.socialFooter | false;
    },
  },
  created() {
    this.socialFooter = this.$route.meta.socialFooter;
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  .body {
    display: flex;
    justify-content: space-between;
    .section {
      flex: 1;
      text-align: left;
      .heading {
        font-weight: 700;
      }
      .routes {
        display: flex;
        flex-direction: column;
        .route {
          margin-top: 15px;
          color: silver;
          font-size: 12px;
        }
      }
    }
    .right-section {
      width: 300px;
      .social-media-links {
        margin-top: 40px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
  .end-line {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .copyright {
      flex: 1;
      .text {
        color: whitesmoke;
        text-align: left;
      }
    }
  }
}

.fimg2 {
  width: 30px;
}

.cleaner-signup-btn {
  margin-left: auto;
  background-color: white;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  button {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 44px;
    img {
      height: 16px;
      width: auto;
    }
  }
}

@media only screen and (max-width: 500px) {
  * {
    text-align: center !important;
  }
  .body {
    justify-content: center !important;
    flex-wrap: wrap;
    .section {
      min-width: 50%;
    }
  }

  .text {
    text-align: center !important;
  }

  strong {
    display: block;
  }
  .cleaner-signup-btn {
    margin-right: auto;
  }
}
</style>