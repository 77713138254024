import Vue from 'vue';
import Router from 'vue-router';
import config from '@/config/config';
import PublicLayout from './views/layout/PublicLayout/PublicLayout.vue';
// import CustomerLayout from '';
// import CleanerLayout from '';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    return { x: 0, y: 0 };
  },
  routes: [
    // Default route go to landing page or home screen.
    {
      path: '/',
      name: '',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'LandingPage',
          component: () => import('./views/Public/LandingPage/LandingPage.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: 'order/payment/:id/:plan?',
          name: 'OrderPayment',
          component: () => import('./views/Public/OrderPayment/OrderPayment.vue'),
          meta: { auth: false }
        },
        {
          path: '/book-cleaning',
          name: 'book-cleaning',
          component: () => import('./views/Public/PostGig2/PostGig.vue'),
          meta: { auth: false, action: 'book-cleaning', nosticky: true }
        },
        {
          path: '/cleaning-types/book-house-cleaning/',
          name: 'book-cleaning',
          component: () => import('./views/Public/PostGig2/PostGig.vue'),
          meta: { auth: false, page: 'book-cleaning', action: 'book-cleaning', nosticky: true}
        },
        {
          path: '/house-cleaning-app/login/',
          name: 'login',
          component: () => import('./views/Public/LogIn/LogIn.vue'),
          meta: { auth: false }
        },
        {
          path: '/contests',
          name: 'contest',
          component: () => import('./views/Public/Contest/Contest.vue'),
          meta: { auth: false }
        },
        {
          path: '/forget-password',
          name: 'forgetPassword',
          component: () => import('./views/Public/ForgetPassword/index.vue'),
          meta: { auth: false }
        },
        {
          path: '/reset-password/:code',
          name: 'resetPassword',
          component: () => import('./views/Public/ResetPassword/ResetPassword.vue'),
          meta: { auth: false }
        },
        {
          path: '/agreement/',
          // redirect: {name: 'Agreement'}
          name: 'Agreement',
          component: () => import('./views/Public/Agreement/Agreement.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/house-cleaners/contractors/agreement/',
          name: 'Agreement',
          component: () => import('./views/Public/Agreement/Agreement.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/payment/:status',
          name: 'Payment',
          component: () => import('./views/Public/PaymentPages/PaymentReview/PaymentReview.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/order-review/:unique_id',
          name: 'OrderReview',
          component: () => import('./views/Public/PaymentPages/PaymentPreview/PaymentPreview.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/consumer-privacy-policy/',
          name: 'consumer-privacy-policy',
          component: () => import('./views/Public/Consumer-privacy-policy/Consumer-privacy-policy.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: 'consumer-terms-and-conditions',
          name: 'consumer-terms-and-conditions',
          component: () => import('./views/Public/ConsumerTerms/Terms.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/house-cleaning-services/consumer-terms-and-conditions/',
          name: 'consumer-terms-and-conditions',
          component: () => import('./views/Public/ConsumerTerms/Terms.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: 'fastart-for-merchants/',
          name: 'FastartForMerchants',
          component: () => import('./views/Public/FastartForMerchants/FastartForMerchants.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/house-cleaning-jobs/fastart-for-merchants/',
          name: 'FastartForMerchants',
          component: () => import('./views/Public/FastartForMerchants/FastartForMerchants.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/merchants/local-laws-us',
          name: 'LocalLawUs',
          component: () => import('./views/Public/merchants/LocalLawUs/LocalLawUs.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/prohibited-behaviors/',
          name: 'ProhibitedBehaviors',
          component: () => import('./views/Public/ProhibitedBehaviors/ProhibitedBehaviors.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/house-cleaning-services/safety/prohibited-behaviors/',
          name: 'ProhibitedBehaviors',
          component: () => import('./views/Public/ProhibitedBehaviors/ProhibitedBehaviors.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: '/cancellation-policy',
          name: 'cancellation-policy',
          component: () => import('./views/Public/CancellationPolicy/Cancellation.vue'),
          meta: { auth: false, socialFooter: true }
        },
        {
          path: 'download-app',
          name: 'DownloadAppPage',
          component: () => import('./views/Public/DownloadAppPage/DownloadAppPage.vue'),
          meta: { auth: false, socialFooter: false }
        },
        {
          path: 'house-cleaning/download-app',
          name: 'DownloadAppPage',
          component: () => import('./views/Public/DownloadAppPage/DownloadAppPage.vue'),
          meta: { auth: false, socialFooter: false }
        },
        {
          path: '/faq/',
          name: 'faq',
          component: () => import('./views/Public/Faq/faq.vue'),
          meta: { auth: false, page: 'faq'}
        },
        {
          path: '/house-cleaning/inquiries/faq/',
          name: 'faq',
          component: () => import('./views/Public/Faq/faq.vue'),
          meta: { auth: false, page: 'faq'}
        },
        {
          path: '/freshnest-subscriptions/',
          name: 'freshnessSubscription',
          component: () => import('./views/Public/FreshnestSubscriptions/FreshnestSubscription.vue'),
          meta: { auth: false, page: 'freshnest-subscription'}
        },
        {
          path: '/house-cleaning/regular/freshnest-subscriptions/',
          name: 'freshnessSubscription',
          component: () => import('./views/Public/FreshnestSubscriptions/FreshnestSubscription.vue'),
          meta: { auth: false, page: 'freshnest-subscription'}
        },
        {
          path: '/mock-user/:mock_user_email/:code',
          name: 'mock-user',
          component: () => import('./views/Public/MockUserLogin/MockUserLogin.vue'),
          meta: { auth: false }
        },
        {
          path: '/news',
          name: 'blogs',
          component: () => import('./views/Public/NewsBlog/NewsFeaturedPage/NewsFeaturedPage.vue'),
          meta: { socialFooter: true }
        },
      ]
    },
    // {
    //   path: '/cleaning-types/book-house-cleaning/',
    //   name: 'book-cleaning',
    //   component: () => import('./views/Public/PostGig/PostGig.vue'),
    //   meta: { auth: false, page: 'book-cleaning'}
    // },
    {
      path: '/order-confirmation/:id',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'orderConfirmation',
          component: () => import('./views/Public/PostGig/Congratulations/Congratulations.vue'),
          meta: { socialFooter: true, data: {} }
        }
      ]
    },
    {
      path: '/agreement-mobile',
      name: 'Agreement',
      component: () => import('./views/Public/Agreement/Agreement.vue'),
      meta: { auth: false, socialFooter: false }
    },
    {
      path: '/prohibited-behaviors-mobile',
      name: 'ProhibitedBehaviors',
      component: () => import('./views/Public/ProhibitedBehaviors/ProhibitedBehaviors.vue'),
      meta: { auth: false, socialFooter: false }
    },
    {
      path: '/consumer-privacy-policy-mobile',
      name: 'consumer-privacy-policy',
      component: () => import('./views/Public/Consumer-privacy-policy/Consumer-privacy-policy.vue'),
      meta: { auth: false, socialFooter: false }
    },
    {
      path: '/fastart-for-merchants-mobile',
      name: 'FastartForMerchantsMobile',
      component: () => import('./views/Public/FastartForMerchants/FastartForMerchants.vue'),
      meta: { auth: false, socialFooter: false }
    },
    {
      path: '/consumer-terms-and-conditions-mobile',
      name: 'consumer-terms-and-conditions-mobile',
      component: () => import('./views/Public/ConsumerTerms/Terms.vue'),
      meta: { auth: false, socialFooter: false }
    },
    
    // --------------------------------------------------------------------------------------
    // Registration
    {
      path: '/house-cleaners-app/signup/customer/',
      component: PublicLayout,
      children: [
        {
          path: 'code/:code',
          name: 'signup.customer.account',
          component: () => import('./views/Public/SignUp/SignUp.vue'),
          meta: { type: 'customer' }
        },
        {
          path: '',
          name: 'signup.customer.account',
          component: () => import('./views/Public/SignUp/SignUp.vue'),
          meta: { type: 'customer' }
        },
        {
          path: 'details',
          name: 'signup.customer.details',
          component: () => import('./views/Public/SignUp/Customer/Details/Details.vue'),
          meta: { type: 'customer', action: 'sign-up' }
        },
        {
          path: 'confirm-email',
          name: 'signup.customer.confirm-email-msg',
          component: () => import('./views/Public/SignUp/ConfirmEmailMsg/ConfirmEmailMsg.vue'),
          meta: { type: 'customer' }
        }
      ]
    },
    {
      path: '/house-cleaning-jobs/signup/cleaner',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'signup.cleaner.account',
          component: () => import('./views/Public/SignUp/SignUp.vue'),
          meta: { type: 'cleaner' }
        },
        {
          path: 'details',
          name: 'signup.cleaner.details',
          component: () => import('./views/Public/SignUp/Cleaner/Details/Details.vue'),
          meta: { type: 'cleaner' }
        },
        {
          path: 'confirm-email',
          name: 'signup.cleaner.confirm-email-msg',
          component: () => import('./views/Public/SignUp/ConfirmEmailMsg/ConfirmEmailMsg.vue'),
          meta: { type: 'customer' }
        }
      ]
    },
    // Signup common screens
    {
      path: '/signup/verify-email/:code',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'signup.verify-email',
          component: () => import('./views/Public/SignUp/VerifyEmail/VerifyEmail.vue'),
          meta: { socialFooter: true }
        }
      ]
    },

    // Express sign up
    {
      path: '/job-invite-signup/',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'express-signup.cleaner.account',
          component: () => import('./views/Public/ExpressSignUp/landing_page/landing_page.vue'),
          meta: { type: 'cleaner', action: 'express_signup' }
        },
       // TODO: can use in future if needed
        // {
        //   path: '/express-signup/cleaner',
        //   name: 'express-signup.cleaner.account',
        //   component: () => import('./views/Public/express_signup/SignUp.vue'),
        //   meta: { type: 'cleaner' }
        // },
        // {
        //   path: 'details',
        //   name: 'signup.cleaner.details',
        //   component: () => import('./views/Public/SignUp/Cleaner/Details/Details.vue'),
        //   meta: { type: 'cleaner' }
        // },
        // {
        //   path: 'confirm-email',
        //   name: 'signup.cleaner.confirm-email-msg',
        //   component: () => import('./views/Public/SignUp/ConfirmEmailMsg/ConfirmEmailMsg.vue'),
        //   meta: { type: 'customer' }
        // }
      ]
    },

    {
      path: '/customer-feedback/:uuid',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'customer-feedback',
          component: () => import('./views/Public/RatingAndFeedback/RatingAndFeedbackDialog.vue'),
          meta: { auth: false, socialFooter: false }
          }
      ]
    },

    {
      path: '/order-refund/:unique_id',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'order-refund',
          component: () => import('./views/Public/CustomerRefund/CustomerRefund.vue'),
          meta: { auth: false, socialFooter: false }
          }
      ]
    },
    // End Registration
    // --------------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------------
    // News Blog
    {
      path: '/house-cleaning-tips/blog/',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'blogs',
          component: () => import('./views/Public/NewsBlog/NewsFeaturedPage/NewsFeaturedPage.vue'),
          meta: { socialFooter: true }
        },
        {
          path: 'blog-details/:url_id',
          name: 'blogs.blog-details',
          component: () => import('./views/Public/NewsBlog/NewsDetails/NewsDetails.vue'),
          meta: { socialFooter: true }
        }

      ]
    },

    // Nearby / location search pages
    {
      path: '/cleaning-services',
      component: PublicLayout,
      children: [
        {
          path: 'house-cleaning',
          name: 'house-cleaning',
          component: () => import('./views/Public/nearby-search/LocationPage.vue'),
          meta: { auth: false, socialFooter: false }
        },
        {
          path: 'house-cleaning/nearme',
          name: 'house-cleaning.nearme',
          component: () => import('./views/Public/nearby-search/LocationPage.vue'),
          meta: { auth: false, socialFooter: false, nearme: true }
        },
        {
          path: ':state/house-cleaning',
          name: 'house-cleaning.state',
          component: () => import('./views/Public/nearby-search/LocationPage.vue'),
          meta: { auth: false, socialFooter: false }
        },
        {
          path: ':state/:city/house-cleaning',
          name: 'house-cleaning.state.city',
          component: () => import('./views/Public/nearby-search/LocationPage.vue'),
          meta: { auth: false, socialFooter: false }
        }
      ]
    },

    // End News Blog
    // --------------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------------
    // Customer Routes
    {
      path: '/customer',
      component: () => import('./views/layout/CustomerLayout/CustomerLayout.vue'), // To perform security checks regarding loading the customer related screens only
      children: [
        {
          path: '',
          name: 'customer.dashboard',
          component: () => import('./views/Customer/Dashboard/Dashboard.vue'),
          meta: { auth: true }
        },
        {
          path: 'post-gig',
          redirect: 'post-cleaning-request'
        },
        {
          path: 'post-cleaning-request',
          name: 'customer.post.gig',
          component: () => import('./views/Customer/PostGig/PostGig.vue'),
          meta: { auth: true, action: 'create' }
        },
        {
          path: 'post-cleaning-request/:code',
          name: 'customer.post.gig',
          component: () => import('./views/Customer/PostGig/PostGig.vue'),
          meta: { auth: true, action: 'create' }
        },
        {
          path: 'gigs/edit-draft-gig/:id',
          redirect: 'cleaning-requests/edit-draft/:id'
        },
        {
          path: 'cleaning-requests/edit-draft/:id',
          name: 'customer.draft.gig',
          component: () => import('./views/Customer/PostGig/PostGig.vue'),
          meta: { auth: true, action: 'draft' }
        },
        {
          path: 'chats/:chatRoom?',
          name: 'customer.chats',
          component: () => import('./views/Customer/Shared/Chats/Chats.vue'),
          meta: { auth: true }
        },
        {
          path: 'notifications',
          name: 'customer.notifications',
          component: () => import('./views/Customer/Shared/Notifications/Notifications.vue'),
          meta: { auth: true }
        },
        {
          path: 'gigs',
          redirect: 'cleaning-requests'
        },
        {
          path: 'cleaning-requests',
          name: 'customer.gigs',
          component: () => import('./views/Customer/Gigs/Gigs.vue'),
          meta: { auth: true }
        },
        {
          path: 'gigs/details/:id',
          redirect: 'cleaning-requests/details/:id'
        },
        {
          path: 'cleaning-requests/details/:id',
          name: 'customer.gigs.details',
          component: () => import('./views/Customer/Gigs/Details/Details.vue'),
          meta: { auth: true }
        },
        {
          path: 'gigs/invoice/:id',
          redirect: 'cleaning-requests/invoice/:id'
        },
        {
          path: 'cleaning-requests/invoice/:id',
          name: 'customer.gigs.invoice',
          component: () => import('./views/Customer/Gigs/Details/Invoice/Invoice.vue'),
          meta: { auth: true }
        },
        {
          path: 'become-cleaner',
          name: 'customer.become-cleaner',
          component: () => import('./views/Customer/BecomeCleaner/BecomeCleaner.vue'),
          meta: { auth: true }
        },
        {
          path: 'wallet',
          name: 'customer.wallet',
          component: () => import('./views/Customer/Wallet/Wallet.vue'),
          meta: { auth: true }
        },
        // Setting Pages 
        {
          path: 'setting/profile',
          name: 'customer.setting.profile',
          component: () => import('./views/Customer/Setting/Setting.vue'),
          meta: { auth: true, page: 'profile' }
        },
        {
          path: 'setting/payment-options',
          name: 'customer.setting.payment-options',
          component: () => import('./views/Customer/Setting/Setting.vue'),
          meta: { auth: true, page: 'payment-options' }
        },
        {
          path: 'setting/invite-friend',
          name: 'customer.setting.invite-friend',
          component: () => import('./views/Customer/Setting/Setting.vue'),
          meta: { auth: true, page: 'invite-friend' }
        },
        {
          path: 'setting/profile/update',
          name: 'customer.setting.profile.update',
          component: () => import('./views/Customer/Setting/Setting.vue'),
          meta: { auth: true, page: 'profile-form' }
        },
        {
          path: 'setting/profile/change-password',
          name: 'customer.setting.profile.change-password',
          component: () => import('./views/Customer/Setting/Setting.vue'),
          meta: { auth: true, page: 'change-password' }
        },

        // Help Pages
        {
          path: 'setting/help',
          name: 'customer.setting.help',
          component: () => import('./views/Customer/Shared/Help/Help.vue'),
          meta: { auth: true, page: 'help' }
        },
        {
          path: 'setting/ticket-list',
          name: 'customer.setting.ticket-list',
          component: () => import('./views/Customer/Shared/Help/Tickets/List/List.vue'),
          meta: { auth: true, page: 'help' }
        },
        {
          path: 'setting/view-ticket/:id',
          name: 'customer.setting.view-ticket',
          component: () => import('./views/Customer/Shared/Help/Tickets/View/View.vue'),
          meta: { auth: true, page: 'help' }
        },
        {
          path: 'refer-friend',
          name: 'customer.ReferFriend',
          component: () => import('./views/Customer/Shared/ReferFriendLandingPage/ReferFriendLandingPage.vue'),
          meta: { auth: true, socialFooter: false }
        },
        {
          path: 'subscription',
          name: 'customer.subscription',
          component: () => import('./views/Customer/Subscription/SubscriptionList.vue'),
          meta: {auth: true}
        },
        {
          path: 'subscription/:id',
          name: 'customer.subscription.detail',
          component: () => import('./views/Customer/Subscription/SubscriptionDetail.vue'),
          meta: {auth: true}
        },
        {
          path: 'subscription-payment/:subscription_id/:gig_id',
          name: 'customer.subscription.payment',
          component: () => import('./views/Customer/PostGig/PostGig.vue'),
          meta: {auth: true, action: 'subscription_payment'}
        },
        {
          path: 'subscription/edit/:subscription_id',
          name: 'customer.subscription.edit',
          component: () => import('./views/Customer/PostGig/PostGig.vue'),
          meta: { auth: true, action: 'subscription_edit' }
        },
        {
          path: 'feedback/:id',
          name: 'customer.gigs.feedback',
          component: () => import('./views/Customer/customerfeedback/FeedbackFormDialog/FeedbackFormDialog.vue'),
          meta: { auth: true }
        },
      ]
    },

    // --------------------------------------------------------------------------------------
    // Cleaner Routes
    {
      path: '/cleaner',
      component: () => import('./views/layout/CleanerLayout/CleanerLayout.vue'), // To perform security checks regarding loading the cleaner related screens only
      children: [
        {
          path: '',
          name: 'cleaner.dashboard',
          component: () => import('./views/Cleaner/Dashboard/Dashboard.vue'),
          meta: { auth: true }
        },
        {
          path: 'gigs',
          redirect: 'cleaning-requests'
        },
        {
          path: 'cleaning-requests',
          name: 'cleaner.gigs',
          component: () => import('./views/Cleaner/Gigs/Gigs.vue'),
          meta: { auth: true }
        },
        // {
        //   path: 'gigs/details/:id',
        //   redirect: 'cleaning-requests/details/:id'
        // },
        {
          path: 'cleaning-requests/details/:gig_id/:gig_cleaner_id',
          name: 'cleaner.gigs.details',
          component: () => import('./views/Cleaner/Gigs/Details/Details.vue'),
          meta: { auth: true }
        },
        {
          path: 'chats/:chatRoom?',
          name: 'cleaner.chats',
          component: () => import('./views/Customer/Shared/Chats/Chats.vue'),
          meta: { auth: true }
        },
        {
          path: 'notifications',
          name: 'cleaner.notifications',
          component: () => import('./views/Customer/Shared/Notifications/Notifications.vue'),
          meta: { auth: true }
        },
        {
          path: 'job-invitations',
          name: 'cleaner.job-invitations',
          component: () => import('./views/Cleaner/JobInvitations/JobInvitations.vue'),
          meta: { auth: true }
        },
        {
          path: 'become-customer/details',
          name: 'cleaner.become-customer.detail',
          component: () => import('./views/Public/SignUp/Customer/Details/Details.vue'),
          meta: { auth: true, action: 'become-customer' }
        },
        {
          path: 'become-customer',
          name: 'cleaner.become-customer',
          component: () => import('./views/Cleaner/BecomeCustomer/BecomeCustomer.vue'),
          meta: { auth: true }
        },
        {
          path: 'wallet',
          name: 'cleaner.wallet',
          component: () => import('./views/Cleaner/Wallet/Wallet.vue'),
          meta: { auth: true }
        },
        // Setting Pages
        {
          path: 'setting/profile',
          name: 'cleaner.setting.profile',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'profile' }
        },
        {
          path: 'setting/profile/update',
          name: 'cleaner.setting.profile.update',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'profile-form' }
        },
        {
          path: 'setting/legal-details/update',
          name: 'cleaner.setting.legal-details.update',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'legal-details-form' }
        },
        {
          path: 'setting/payment-setting',
          name: 'cleaner.setting.payment-setting',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'payment-setting' }
        },
        {
          path: 'setting/invite-friend',
          name: 'cleaner.setting.invite-friend',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'invite-friend' }
        },
        {
          path: 'setting/payment-setting/update',
          name: 'cleaner.setting.payment-setting.update',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'payment-setting-form' }
        },
        {
          path: 'setting/profile/change-password',
          name: 'cleaner.setting.profile.change-password',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'change-password' }
        },
        {
          path: 'setting/location-and-legal-detail',
          name: 'cleaner.setting.location-and-legal-detail',
          component: () => import('./views/Cleaner/Setting/Setting.vue'),
          meta: { auth: true, page: 'location-and-legal-detail' }
        },
        // Help Pages
        {
          path: 'setting/help',
          name: 'cleaner.setting.help',
          component: () => import('./views/Customer/Shared/Help/Help.vue'),
          meta: { auth: true, page: 'help' }
        },
        {
          path: 'setting/ticket-list',
          name: 'cleaner.setting.ticket-list',
          component: () => import('./views/Customer/Shared/Help/Tickets/List/List.vue'),
          meta: { auth: true, page: 'help' }
        },
        {
          path: 'setting/view-ticket/:id',
          name: 'cleaner.setting.view-ticket',
          component: () => import('./views/Customer/Shared/Help/Tickets/View/View.vue'),
          meta: { auth: true, page: 'help' }
        },
        {
          path: 'refer-friend',
          name: 'cleaner.ReferFriend',
          component: () => import('./views/Customer/Shared/ReferFriendLandingPage/ReferFriendLandingPage.vue'),
          meta: { auth: true, socialFooter: false }
        },
        // subscription
        {
          path: 'recurring-cleanings',
          name: 'cleaner.recurring-cleanings',
          component: () => import('./views/Cleaner/recurring-cleaning/RecurringCleaningList.vue'),
          meta: { auth: true }
        },

      ]
    },

    {
      path: '*',
      component: PublicLayout,
      children: [
        {
          path: '',
          name: 'error',
          component: () => import('./views/Error/Error.vue')
        }
      ]

    },

    // -
    // -
    // -
    // -
    // -
    // -
    // -
    // -
    // -
    // - Old Stuff
    // --------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------
    // {
    //   path: '/become-customer',
    //   name: 'BecomeCustomer',
    //   component: () => import('./components/Dashboard/BecomeCustomer/BecomeCustomer.vue')
    // },
    // {
    //   path: '/agreement',
    //   name: 'agreement',
    //   component: () => import('./components/Agreement/Agreement.vue')
    // },
    // {
    //   path: '/reviews',
    //   name: 'reviews',
    //   component: import('./components/Dashboard/Reviews/Reviews.vue')
    // },
    // {
    //   path: '/payments',
    //   name: 'payments',
    //   component: import('./components/Dashboard/Payments/Payments.vue')
    // },
  ]
});
router.beforeEach((to, from, next) => {
  // If user is logged in and we need to restrict access on some pages. i.e. logged in user cannot go to login page
  if (localStorage.getItem(config.cookie.bearerName)) {
    if (
      to.path === '/' ||
      to.path === '/house-cleaning-app/login/' || to.path === '/house-cleaners-app/signup/customer/' || to.path === '/house-cleaners-app/signup/customer/details' || to.path === '/house-cleaners-app/signup/customer/confirm-email' ||
      to.path === '/house-cleaning-jobs/signup/cleaner/' || to.path === '/house-cleaning-jobs/signup/cleaner/details' || to.path === '/house-cleaning-jobs/signup/cleaner/confirm-email' || to.path === '/house-cleaning-jobs/signup/cleaner/' ||
      to.name === 'signup.verify-email'
    ) {
      if (localStorage.getItem(config.cookie.userType) === config.userTypes.customer) {
        next({ path: '/customer' });
      } else if (localStorage.getItem(config.cookie.userType) === config.userTypes.cleaner) {
        next({ path: '/cleaner' });
      }
    }
  }

  if (!to.meta.auth) {
    next();
  } else if (to.meta.auth && localStorage.getItem(config.cookie.bearerName)) {
    next();
  } else {
    let express_login = false;
    localStorage.setItem('redirect_Url', to.fullPath)
    const urlParams = new URLSearchParams(to.fullPath);

    // Get the value of the 'gig_id' parameter
    const gigId = urlParams.get('gig_id');
    if (gigId) {
      const post_actions = [
        {
          key: "send_invite",
          data: {
            gig_id: gigId,
          }
        }
      ];
      localStorage.setItem("post_actions", JSON.stringify(post_actions));
      express_login = true;
    }
    if(express_login) {
      next({path: '/job-invite-signup'})
    } else {
      next({ path: '/house-cleaning-app/login/' });
    }
  }
});

export default router;
